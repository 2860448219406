/* see https://github.com/themesberg/flowbite-admin-dashboard/blob/main/src/css/input.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwind dark mode background */
@media (prefers-color-scheme: dark) {
  .dark {
    @apply bg-gray-900 text-gray-300;
  }
}

/* calendar styles */

.fc .fc-toolbar {
  @apply flex-row-reverse justify-end px-4 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  @apply mb-5 !important;
}

.fc .fc-toolbar-title {
  @apply text-lg text-gray-900 font-semibold !important;
}

.fc .fc-today-button {
  @apply rounded-lg border border-gray-200 bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 !important;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  @apply mx-2 !important;
}

.fc .fc-button-group .fc-prev-button,
.fc .fc-button-group .fc-next-button {
  @apply bg-white border-0 text-gray-500 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 rounded inline-flex focus:bg-gray-100 focus:ring-1 focus:ring-gray-100 justify-center !important;
}

.fc .fc-scrollgrid {
  @apply border-l-0 border-gray-200 !important;
}

.fc .fc-daygrid-day-frame {
  @apply border-gray-200 !important;
}

.fc .fc-col-header-cell-cushion {
  @apply py-3 text-base text-gray-900 font-semibold !important;
}

.fc-theme-standard th {
  @apply border-0 border-b border-gray-200 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  @apply mr-2 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  @apply ml-2 !important;
}

.fc .fc-event .fc-event-main {
  @apply p-2 bg-primary-700 hover:bg-primary-800 !important;
}

.fc .fc-h-event .fc-event-main-frame {
  @apply text-xs font-semibold !important;
}

.fc .fc-daygrid-day-frame {
  @apply hover:bg-gray-50 cursor-pointer !important;
}

/* Transparent background minimalist scrollbar with rounded ends */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 6px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
  scale: 1.1;
}

/* Custom scrollbar for Firefox */

/* width */
* {
  scrollbar-width: thin;
}

/* Track */
* {
  scrollbar-color: #d1d5db transparent;
}

/* Handle on hover */

* {
  scrollbar-color: #9ca3af transparent;
}